import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import ArticleList from 'components/ArticleList'
import Hero from 'components/Hero'
import BrandTicker from 'components/BrandTicker'

import Layout from 'components/Layout'
import { Spacing50 } from 'styles/sharedStyle'

import StacklaWidget from 'components/StacklaWidget'
import renderContent from 'utils/componentRenderer'
import {
  filterRepeatedContent,
  groupContentTypes,
  NON_REPEATED_CONTENT_TYPES_T02,
} from 'utils/contentUtils'

const DineLandingPage = (props) => {
  const {
    data,
    location,
    pageContext: { pageLevel },
  } = props
  const {
    dineEstablishments,
    page,
    page: {
      hero,
      content,
      additionalContent,
      stacklaCarousel,
      ticker,
      jsonLdSchema,
      village,
    },
    labels,
  } = data

  const tickerText = ticker
    ? [
        ticker.tickerText1.tickerText1,
        ticker.tickerText2.tickerText2,
        ticker.tickerText3.tickerText3,
      ]
    : []

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        logo: page.brandLogo,
        isCondensed: hero.isCondensed,
        opacity: hero.opacity,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const contentToRender =
    !!content &&
    groupContentTypes(
      filterRepeatedContent(content, NON_REPEATED_CONTENT_TYPES_T02)
    )

  const additionalContentToRender =
    !!additionalContent &&
    groupContentTypes(
      filterRepeatedContent(additionalContent, NON_REPEATED_CONTENT_TYPES_T02)
    )

  const {
    dineLandingEyebrow,
    dineLandingHeading,
    dineLandingLoadMore,
    dineAllCategoriesLabel,
  } = (labels &&
    labels.edges &&
    labels.edges.length &&
    labels.edges[0].node) || {
    dineLandingEyebrow: '',
    dineLandingHeading: '',
    dineLandingLoadMore: '',
    dineAllCategoriesLabel: 'ALL',
  }

  const dine = dineEstablishments.edges.reduce((arr, article) => {
    arr.push({ ...article.node })
    return arr
  }, [])

  const dineTypeUnique = Array.from(
    new Set(dine.map(({ dineType: { label } }) => label))
  ).sort()

  const dineDropdownOptions = dineTypeUnique.map((dineType, index) => ({
    id: index + 1,
    value: dineType.replace(/\s/g, ''),
    name: dineType,
  }))

  const dineDropdownOptionsWithAllSelector = [
    {
      id: 0,
      value: 'ALL',
      name: dineAllCategoriesLabel || '',
    },
    ...dineDropdownOptions,
  ]

  const categoryFilter = {
    fixedSize: true,
    label: '',
    name: 'categoryFilter',
    options: dineDropdownOptionsWithAllSelector,
  }

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      {tickerText.length > 0 && (
        <BrandTicker
          tickerText={tickerText}
          villageSlug={
            pageLevel === 'collection'
              ? 'The Bicester Collection'
              : page?.village?.name
          }
        />
      )}

      <Spacing50 />

      {renderContent(contentToRender, props)}

      {dine.length > 0 && (
        <ArticleList
          articles={dine
            .filter((dinePage) => !!dinePage.hero)
            .map(
              ({
                name,
                slug,
                dineType: { label },
                hero: { media: heroMedia },
                pageType,
                // eslint-disable-next-line no-shadow
                village,
                locale,
              }) => ({
                category: label,
                image:
                  heroMedia &&
                  heroMedia[0] &&
                  (heroMedia[0].videoPlaceholder || heroMedia[0]),
                heading: name,
                slug,
                pageType,
                village,
                locale,
              })
            )}
          eyebrow={dineLandingEyebrow}
          header={dineLandingHeading}
          loadMore={dineLandingLoadMore}
          pathname={location.pathname}
          categoryFilter={categoryFilter}
          villageName={village?.name}
          pageTypeComponent="dine"
        />
      )}

      {renderContent(additionalContentToRender, props)}

      <Spacing50 />

      {!!stacklaCarousel && !!stacklaCarousel.code && (
        <StacklaWidget
          code={stacklaCarousel.code.code}
          eyebrow={stacklaCarousel.eyebrow}
          headline={stacklaCarousel.headline}
          ctaLabel={stacklaCarousel.ctaLabel}
          ctaUrl={stacklaCarousel.ctaUrl}
        />
      )}
    </Layout>
  )
}

DineLandingPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
}

export const dineLandingPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String
    #  $dateNow: Date!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $villageCode: String
    $villageSlug: String
    $pageTypeSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateSectionLandingT02(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      locale: node_locale
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
          locale: node_locale
        }
        villagePlaceholderImage: image {
          __typename
          ... on Node {
            ... on ContentfulEntityImageEnt02 {
              ...ResponsiveImageQuery
            }
          }
        }
        openingHours {
          ...villageOpeningHours
        }
        openingStatusLabel
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
        }
        defaultLocale
      }
      hero {
        title
        headline
        eyebrow
        isCondensed
        opacity
        ...heroMediaQuery
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT02
        }
      }
      additionalContent {
        __typename
        ... on Node {
          ...multipleComponentsT02
        }
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
      stacklaCarousel {
        code {
          code
        }
        eyebrow
        headline
        ctaLabel
        ctaUrl
      }
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    dineEstablishments: allContentfulPageTemplateRestaurantDetailT07(
      filter: {
        village: { code: { eq: $villageCode } }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: [published, name], order: ASC }
    ) {
      edges {
        node {
          name
          locale: node_locale
          pageType {
            slug
          }
          village {
            villageSlug: slug
          }
          slug
          dineType {
            label
          }
          hero {
            ...heroMediaQuery
          }
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
          dineLandingEyebrow
          dineLandingHeading
          dineAllCategoriesLabel
          dineLandingLoadMore
        }
      }
    }
    #    featuredOffers: allContentfulEntityOfferEnt05(
    #      filter: {
    #        node_locale: { eq: $nodeLocale }
    #        offerRunFinishDate: { gte: $dateNow }
    #      }
    #      sort: { fields: offerRunFinishDate }
    #    ) {
    #      nodes {
    #        id: contentful_id
    #        location {
    #          locationId: contentful_id
    #        }
    #        eyebrow
    #        headline
    #        description {
    #          description
    #        }
    #        offerDetailUrl
    #        offerExpirationLabel
    #        offerRunStartDate(formatString: "D MMMM")
    #        offerRunFinishDate(formatString: "D MMMM YYYY")
    #        offerImage {
    #          ...heroMediaQuery
    #        }
    #      }
    #    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
  }
`

export default DineLandingPage
